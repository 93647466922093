<script>
  import { loadModules } from "esri-loader";
  import { mapViewStore } from "../stores";

  let searchWidget;

  async function createSearchWidget(domNode) {
    const [Extent, Search] = await loadModules([
      "esri/geometry/Extent",
      "esri/widgets/Search",
    ]);

    // the search widget will be placed in the header outside of the MapView's own UI
    searchWidget = new Search({
      disabled: true,
      container: domNode,
      includeDefaultSources: function (sourcesResponse) {
        // ignore other default sources configured by this Portal, we just want the AGOL world geocoder
        sourcesResponse.defaultSources = sourcesResponse.defaultSources.filter(
          (s) => s.name === "ArcGIS World Geocoding Service"
        );

        // modify the AGOL world geocoder to only search within CONUS
        const agolLocatorSearchSource = sourcesResponse.defaultSources.getItemAt(
          0
        );
        if (agolLocatorSearchSource) {
          agolLocatorSearchSource.countryCode = "US";
          agolLocatorSearchSource.filter = {
            // CONUS extent for the 48 states and DC
            geometry: new Extent({
              xmin: -13888506.6579,
              ymin: 2817033.0579999983,
              xmax: -7452848.586999999,
              ymax: 6340332.527500002,
              spatialReference: {
                latestWkid: 3857,
                wkid: 102100,
              },
            }),
          };
        }

        return sourcesResponse.defaultSources;
      },
      popupEnabled: false,
      resultGraphicEnabled: false,
    });
  }

  $: if ($mapViewStore && searchWidget) {
    searchWidget.view = $mapViewStore;
    searchWidget.disabled = false;
  }
</script>

<li class="usa-nav__primary-item">
  <div use:createSearchWidget class="esri-search-container" />
</li>

<style>
  .esri-search-container {
    width: 100%;
  }

  /* desktop menu breakpoint */
  @media (min-width: 64em) {
    .usa-nav__primary-item {
      align-self: center;
      /* set a default width so that other nearby elements won't "jump" while this widget starts up */
      width: 250px;
    }
  }
</style>
