import appConfig from "./appConfig";
import { loadModules } from "esri-loader";

export const getLayerByTitle = function (map, layerTitle) {
  console.log("layerTitle")
  console.log(":",layerTitle )
  var title = layerTitle
  if(layerTitle.value) {
    title = layerTitle.value;
  }
  const layerInstance = map.layers
    .toArray()
    .find((l) => l.title === title);
  return layerInstance;
};

export const queryForAllLayers = async function (map) {
  var layers = [];
  for(let i=0; i<map.layers.items.length; i++) {
    layers.push(map.layers.items[i].title)
  }
  return layers;
}
// returns a Promise
export const queryForUniqueStates = async function (
  statesFeatureLayer, // US states feature layer instance
  stateNameAttribute, // name of attribute field that contains unique US state names
  stateAbbreviationAttribute // name of attribute field that contains unique US state abbreviations
) {
  try {
    const query = statesFeatureLayer.createQuery();
    query.outFields = [stateNameAttribute, stateAbbreviationAttribute];
    query.orderByFields = [`${stateNameAttribute} ASC`];
    query.returnGeometry = false;
    query.returnDistinctValues = true;
    query.cacheHint = true;

    const results = await statesFeatureLayer.queryFeatures(query);
    const uniqueStateNames = results.features.map((f) => {
      const stateName = f.attributes[stateNameAttribute];
      const stateAbbreviation = f.attributes[stateAbbreviationAttribute];
      return {
        value: stateName,
        displayText: `${stateName} (${stateAbbreviation})`,
      };
    });

    // resolve promise with array of unique state names
    return uniqueStateNames;
  } catch (error) {
    // reject promise with error info
    throw new Error(error);
  }
};

// returns a Promise
export const queryForUniqueCountiesInAState = async function (
  countiesFeatureLayer, // US counties feature layer instance
  stateName, // chosen state name value
  countyStateNameAttribute, // state field name in the county layer
  countyNameAttribute // county field name in the county layer
) {
  if (
    !countiesFeatureLayer ||
    !stateName ||
    !countyStateNameAttribute ||
    !countyNameAttribute
  ) {
    // resolve promise with empty array
    return [];
  }

  try {
    const query = countiesFeatureLayer.createQuery();
    query.where = `${countyStateNameAttribute} = '${stateName}'`;
    query.outFields = [countyNameAttribute];
    query.orderByFields = [`${countyNameAttribute} ASC`];
    query.returnGeometry = false;
    query.returnDistinctValues = true;

    const results = await countiesFeatureLayer.queryFeatures(query);
    const uniqueCountyNames = results.features.map(
      (f) => f.attributes[countyNameAttribute]
    );

    // resolve promise with array of unique county names
    return uniqueCountyNames;
  } catch (error) {
    // reject promise with error info
    throw new Error(error);
  }
};

// returns a Promise
export const queryForUniqueNassRegions = async function (
  nassRegionsFeatureLayer, // NASS regions feature layer instance
  nassRegionNameAttribute // name of attribute field that contains unique NASS region names
) {
  try {
    const query = nassRegionsFeatureLayer.createQuery();
    query.outFields = [nassRegionNameAttribute];
    query.orderByFields = [`${nassRegionNameAttribute} ASC`];
    query.returnGeometry = false;
    query.returnDistinctValues = true;
    query.cacheHint = true;

    const results = await nassRegionsFeatureLayer.queryFeatures(query);
    const uniqueNASSRegionNames = results.features.map((f) => {
      const nassRegionName = f.attributes[nassRegionNameAttribute];
      return {
        value: nassRegionName,
        displayText: nassRegionName,
      };
    });

    // resolve promise with array of unique NASS region names
    return uniqueNASSRegionNames;
  } catch (error) {
    // reject promise with error info
    throw new Error(error);
  }
};

// returns a Promise
export const handleExportAction = async function (
  exportFeatures, // required: Esri feature graphics instances
  exportYear, // required: year integer
  exportCropTypes, // optional: array of crop type strings to include in a mask
  exportProjectionWKID, // optional: projection
  exportFiletype, // optional: file format
  exportDpiValue,
  cacheKey, // optional: string that remembers the cached export for the next time on the backend
  statusCallback // optional: pending GP job status callback
) {
  try {
    if (!exportFeatures || !exportYear) {
      throw new Error(
        'missing required arguments "exportFeatures" and "exportYear"'
      );
    }

    const [geoprocessor] = await loadModules(["esri/rest/geoprocessor"]);

    // prepare export params for `submitJob`
    const exportFeatureSetAOI = JSON.stringify({
      featureSet: {
        features: exportFeatures.map((f) => ({
          // Esri graphic polygon geometry to Esri json
          geometry: f.geometry.toJSON(),
        })),
        geometryType: "esriGeometryPolygon",
      },
    });

    const params = {
      // 1. gp param `AOI` requires an Esri featureSet json
      AOI: exportFeatureSetAOI,
      // 2. gp param `Year` requires a year value
      Year: exportYear,
    };

    // 3. gp param `Crop_Type` (OPTIONAL)
    if (exportCropTypes.length) {
      params.Crop_Type = exportCropTypes;
    }

    // 4. gp param `Output_Projection` (OPTIONAL)
    if (exportProjectionWKID) {
      params.Output_Projection = exportProjectionWKID;
    }

    // 5. gp param `Output_Type` (OPTIONAL)
    if (exportFiletype) {
      params.Output_Type = exportFiletype;
    }

    // 6. gp param `Cache_Key` (OPTIONAL)
    if (cacheKey) {
      params.Cache_Key = `${cacheKey}_${exportYear}`;
    }

    if (exportDpiValue) {
      params.dpi = exportDpiValue;
    }

    // submit the asynchronous GP job
    const jobInfo = await geoprocessor.submitJob(
      appConfig.exportGeoprocessorUrl,
      params
    );

    const waitforJobCompletionOptions = {};
    if (typeof statusCallback === "function") {
      waitforJobCompletionOptions.statusCallback = statusCallback;
    }

    // wire up job completion async helper
    await jobInfo.waitForJobCompletion(waitforJobCompletionOptions);

    // `resultData.value.url` will have the zip file download location
    const resultData = await jobInfo.fetchResultData("Clipped_Imagery");

    // resolve promise with final job complete info and result data output
    return {
      jobInfo,
      resultData,
    };
  } catch (error) {
    // reject promise with error info
    throw new Error(error);
  }
};

// returns a Promise
export const getPrintLayoutTemplates = async function () {
  try {
    const [esriRequest] = await loadModules(["esri/request"]);

    const response = await esriRequest(
      appConfig.exportWebMapTaskUrl + "?f=json",
      {
        responseType: "json",
      }
    );

    return response.data.parameters
      .find((param) => param.name === "Layout_Template")
      .choiceList.filter((choice) => choice.toUpperCase() !== "MAP_ONLY");
  } catch (error) {
    // reject promise with error info
    throw new Error(error);
  }
};

// returns a Promise
export const handlePrintAction = async function (
  mapView,
  layout, // optional: layout template string
  printQualityDpi = 96, // optional: dpi number value
  exportProjectionWKID, // optional: projection
  scalePreserved = false, // optional: preserve either map scale (true) or extent (false)
  title = "", // optional: document title
  producedBy = "", // optional: author name
  description = "", // optional: extended description
  callback // optional: callback function when mapView has been used by the print params and **might be** safe to manipulate
) {
  try {
    const [PrintTemplate, PrintParameters, SpatialReference, print] =
      await loadModules([
        "esri/rest/support/PrintTemplate",
        "esri/rest/support/PrintParameters",
        "esri/geometry/SpatialReference",
        "esri/rest/print",
      ]);

    const template = new PrintTemplate({
      format: "pdf",
      exportOptions: {
        dpi: Number(printQualityDpi),
      },
      layout,
      layoutOptions: {
        // titleText: "", // unused by the current layout templates
        // authorText: "", // unused by the current layout templates
        customTextElements: [
          { Description: description },
          { "Produced by": producedBy },
          { Title: title },
          { Date: new Date().toLocaleString() },
        ],
      },
      scalePreserved, // https://developers.arcgis.com/javascript/latest/api-reference/esri-rest-support-PrintTemplate.html#scalePreserved
      showLabels: true,
    });

    const params = new PrintParameters({
      view: mapView,
      template,
      outSpatialReference: new SpatialReference({ wkid: exportProjectionWKID }),
    });

    if (typeof callback === "function") {
      // setTimeout isn't ideal,
      // but we don't want to wait until the print.execute() is completely done
      setTimeout(callback, 500);
    }

    const printResponse = await print.execute(
      appConfig.exportWebMapTaskUrl,
      params
    );

    const resultData = {
      value: {
        url: printResponse.url,
      },
    };

    // `resultData.value.url` will have the zip file download location
    // resolve promise with final job complete info and result data output
    return {
      resultData,
    };
  } catch (error) {
    // reject promise with error info
    throw new Error(error);
  }
};

export const cleanupJsapiListener = function (jsapiListener) {
  if (jsapiListener && jsapiListener.remove) {
    jsapiListener.remove();
  }
};

const transparencyOff =
  "https://raw.githubusercontent.com/Esri/calcite-ui-icons/master/icons/layer-24.svg";
const transparencyOn =
  "https://raw.githubusercontent.com/Esri/calcite-ui-icons/master/icons/layer-hide-24.svg";

// returns a Promise
export const establishJSAPIMapViewWidgets = async function (mapView) {
  const [BasemapGallery, Expand, Home, LayerList, ScaleBar, Zoom, watchUtils, Basemap] =
    await loadModules([
      "esri/widgets/BasemapGallery",
      "esri/widgets/Expand",
      "esri/widgets/Home",
      "esri/widgets/LayerList",
      "esri/widgets/ScaleBar",
      "esri/widgets/Zoom",
      "esri/core/watchUtils",
      "esri/Basemap"
    ]);

  const zoomWidget = new Zoom({
    view: mapView,
  });

  const homeWidget = new Home({
    view: mapView,
  });

  const basemapGalleryExpandWidget = new Expand({
    view: mapView,
    content: new BasemapGallery({
      source: [
              Basemap.fromId("topo-vector"),
              Basemap.fromId("dark-gray-vector"), // autocasts to LocalBasemapsSource
              Basemap.fromId("gray-vector"),
              Basemap.fromId("terrain"),
              Basemap.fromId("hybrid"),
              Basemap.fromId("streets-vector")
            ],
      view: mapView,
    }),
    expandTooltip: "Expand Basemap Gallery",
    group: "top-right-expand-group",
    mode: "floating",
  });

  const layerList = new LayerList({
    view: mapView,
    listItemCreatedFunction: function (event) {
      const item = event.item;

      const matchingLegendConfig = appConfig.layerLegends.find(
        (legendConfig) =>
          legendConfig.title.toLocaleLowerCase() ===
          item.layer.title.toLowerCase()
      );

      if (matchingLegendConfig) {
        item.panel = {
          content: "legend",
          open: matchingLegendConfig.initiallyOpen,
          visible: true,
          title: "Toggle legend",
        };
      }

      item.actionsSections = [
        [
          {
            title: "Toggle transparency",
            image: item.layer.opacity === 1 ? transparencyOff : transparencyOn,
            visible: item.layer.opacity >= 0.5,
            id: "layer-transparency",
          },
        ],
      ];

      watchUtils.watch(item.layer, "opacity", (opacity) => {
        layerList.operationalItems.forEach((item) => {
          item.actionsSections.forEach((actionsSection) => {
            actionsSection.forEach((action) => {
              if (action.id === "layer-transparency") {
                if (item.layer.opacity < 0.5) action.visible = false;
                if (item.layer.opacity === 0.5) action.image = transparencyOn;
                else action.image = transparencyOff;
              }
            });
          });
        });
      });
    },
  });

  const layerListExpandWidget = new Expand({
    view: mapView,
    content: layerList,
    expandTooltip: "Expand Layer List",
    group: "top-right-expand-group",
    mode: "floating",
  });

  layerList.on("trigger-action", (evt) => {
    if (evt.action.id === "layer-transparency") {
      const currentOpacity = evt.item.layer.opacity;
      evt.item.layer.opacity = currentOpacity < 1 ? 1 : 0.5;
    }
  });

  const scaleBarWidget = new ScaleBar({ view: mapView, unit: "dual" });

  mapView.ui.add(zoomWidget, "top-left");
  mapView.ui.add(homeWidget, "top-left");
  mapView.ui.add(basemapGalleryExpandWidget, "top-right");
  mapView.ui.add(layerListExpandWidget, "top-right");
  mapView.ui.add(scaleBarWidget, "bottom-left");
};

// (DEPRECATED)
export const createMaskFromPixelData = function (
  pixelData,
  selectedPixelCategories
) {
  if (!selectedPixelCategories.length) {
    // stop early if there are no chosen crops to filter
    return false;
  }

  if (
    pixelData === null ||
    pixelData.pixelBlock === null ||
    pixelData.pixelBlock.pixels === null
  ) {
    // stop early if required JSAPI pixel info is not available
    return false;
  }

  // the pixelBlock stores the values of all pixels visible in the view
  // get the number of pixels in the pixelBlock to assist with looping
  const numPixels = pixelData.pixelBlock.width * pixelData.pixelBlock.height;

  // get the 3 bands of pixels [R[], G[], B[]] visible in the view
  const pixels = pixelData.pixelBlock.pixels;

  // the mask will be used to filter unwanted data
  const mask = [];

  // for each pixel in the block
  for (let i = 0; i < numPixels; i++) {
    // if the [R, G, B] pixel values match any of the selected user crop choices
    // then include it in the mask so it does display
    const match = selectedPixelCategories.find(
      (c) =>
        c.rgb[0] === pixels[0][i] && // R comparison
        c.rgb[1] === pixels[1][i] && // G comparison
        c.rgb[2] === pixels[2][i] // B comparison
    );

    if (match) {
      mask[i] = 1;
    } else {
      // if the pixel values do not match the desired crop choice values
      // then exclude it from the mask so it doesn't display
      mask[i] = 0;
    }
  }

  return mask;
};

export const getCropsUniqueValuesRenderer = function (pixelCategories) {
  return {
    type: "unique-value",
    field: "Value",
    uniqueValueInfos: pixelCategories.map((pixelCategoryInfo) => ({
      value: pixelCategoryInfo.value,
      label: pixelCategoryInfo.displayText,
      symbol: {
        type: "simple-fill",
        color: pixelCategoryInfo.colorSwatch,
      },
    })),
  };
};

// returns a Promise
export const convertShapefileIntoFeatures = async function (uploadFile) {
  try {
    const [esriRequest] = await loadModules(["esri/request"]);
    const formData = new FormData();
    formData.set("f", "json");
    formData.set("filetype", "shapefile");
    formData.set("file", uploadFile);
    formData.set(
      "publishParameters",
      JSON.stringify({
        name: uploadFile.name,
        targetSR: {
          wkid: appConfig.projectWKID,
        },
      })
    );

    const response = await esriRequest(appConfig.generateFeaturesUrl, {
      responseType: "json",
      method: "post",
      body: formData,
    });

    const graphics = [];

    if (response.data) {
      const [Graphic] = await loadModules(["esri/Graphic"]);

      response.data.featureCollection.layers.forEach((layer) => {
        layer.featureSet.features
          .filter(
            (featureJson) => featureJson.geometry && featureJson.geometry.rings
          )
          .forEach((featureJson) => {
            // set type value to help with geometry autocasting from json
            featureJson.geometry.type = "polygon";

            graphics.push(
              new Graphic({
                geometry: featureJson.geometry,
                attributes: featureJson.attributes,
              })
            );
          });
      });
    }

    // resolve promise with array of polygon feature graphic instances
    return graphics;
  } catch (error) {
    // reject promise with error info
    throw new Error(error);
  }
};

// returns a Promise
export const computeImageryStatistics = async function (
  datasetParam,
  statsFeatures, // required: Esri feature graphics instances
  statsYear, // required: year integer
  cacheKey, // optional: string that remembers the cached export for the next time on the backend
  abortSignal, // optional: AbortSignal to cancel the request if no longer needed
  statusCallback // optional: pending GP job status callback
) {
  // just in case an error is thrown via an abort signal,
  // cancel the job in the`catch` to be kind to the server
  let statsGeoprocessor;
  let jobInfo;

  try {
    if (!statsFeatures || !statsYear) {
      throw new Error(
        'missing required arguments "statsFeatures" and "statsYear"'
      );
    }

    const [geoprocessor] = await loadModules(["esri/rest/geoprocessor"]);

    // prepare stats/histograms params for `submitJob`
    const statsFeatureSetAOI = JSON.stringify({
      featureSet: {
        features: statsFeatures.map((f) => ({
          // Esri graphic polygon geometry to Esri json
          geometry: f.geometry.toJSON(),
        })),
        geometryType: "esriGeometryPolygon",
      },
    });
    console.log("Stats Year$$:", statsYear )
    var params = {
      // 1. gp param `AOI` requires an Esri featureSet json
      AOI: statsFeatureSetAOI,
      Dataset_Name: datasetParam,
    };

    if(datasetParam=="CDLS_WM") {
      params = {
        // 1. gp param `AOI` requires an Esri featureSet json
        AOI: statsFeatureSetAOI,
        Dataset_Name: datasetParam,
        // 2. gp param `Year` requires a year value
        Year: statsYear,
      };
    }

    // 3. gp param `Cache_Key` (OPTIONAL)
    if (cacheKey) {
      params.Cache_Key = `${cacheKey}_${statsYear}`;
    }

    // submit the asynchronous GP job
    jobInfo = await geoprocessor.submitJob(
      appConfig.calculateStatisticsGeoprocessorUrl,
      params
    );

    const waitforJobCompletionOptions = {};
    if (abortSignal) {
      waitforJobCompletionOptions.signal = abortSignal;
    }
    if (typeof statusCallback === "function") {
      waitforJobCompletionOptions.statusCallback = statusCallback;
    }

    // wire up job completion helper
    await jobInfo.waitForJobCompletion(waitforJobCompletionOptions);

    // `resultData.value.histograms[0].counts` will have the crop category histogram values
    const resultData = await jobInfo.fetchResultData("Statistics");

    // resolve promise with final job complete info and result data output
    return {
      jobInfo,
      resultData,
    };
  } catch (error) {
    // if an error is thrown via an abort signal,
    // cancel the job to be kind to the server
    if (error.name === "AbortError" && jobInfo) {
      jobInfo.cancelJob(jobInfo.jobId);
    }
    // reject promise with error info
    throw new Error(error);
  }
};

// returns a Promise
export const generatePrintCutoutMaskingFeature = async function (
  exportFeatures // required: Esri feature graphics instances
) {
  if (!exportFeatures) {
    return false;
  }

  const [geometryEngineAsync, webMercatorUtils, Extent] = await loadModules([
    "esri/geometry/geometryEngineAsync",
    "esri/geometry/support/webMercatorUtils",
    "esri/geometry/Extent",
  ]);

  // 1. union all export polygon geometries
  const unionedGeometry = await geometryEngineAsync.union(
    exportFeatures.map((f) => f.geometry)
  );

  // 2. subtract away the unioned geometry from a much larger geometry,
  // in this case a nearly worldwide extent geometry
  const cutoutMaskingGeometry = await geometryEngineAsync.difference(
    // larger masking geometry
    webMercatorUtils.geographicToWebMercator(
      new Extent({
        xmin: -180,
        xmax: 180,
        ymin: -89,
        ymax: 89,
        spatialReference: {
          wkid: 4326,
        },
      })
    ),
    // smaller subtractor geometry
    unionedGeometry
  );

  // return a feature JSON representation,
  // ready to be autocast and used by JSAPI later
  return {
    geometry: cutoutMaskingGeometry,
    symbol: appConfig.cookieCutterSymbol,
  };
};
