export default {
  esriVersion: "4.21",
  portalUrl: "https://pdi.scinet.usda.gov/portal",

  // https://pdienterprise.azurecloudgov.us/portal/home/item.html?id=8181d2325d0745249854214af4b0ed6f
  webmapId: "8181d2325d0745249854214af4b0ed6f",
  projectWKID: "102100",

  // lookup layers by their title in the webmap
  // and configure other attribute fields that the application relies upon
  cdlImageryLayerTitle: "Cropland Data Layer (CDL)",
  soybeansImageryLayerTitle:"Soybeans Frequency (2008 - 2024)",
  cornImageryLayerTitle:"Corn Frequency (2008 - 2024)",
  cottonImageryLayerTitle:"Cotton Frequency (2008 - 2024)",
  wheatImageryLayerTitle:"Wheat Frequency (2008 - 2024)",
  cropMaskImageryLayerTitle:"Crop Mask (2019 - 2024)",
  cultivatedLandImageryLayerTitle: "Cultivated Land (2019-2024)",

  datasetNames : {
    cdlImageryLayerTitle : "CDLS_WM",
    soybeansImageryLayerTitle: "Soybeans_Frequency",
    cornImageryLayerTitle: "Corn_Frequency",
    cottonImageryLayerTitle: "Cotton_Frequency",
    wheatImageryLayerTitle: "Wheat_Frequency",
    cultivatedLandImageryLayerTitle: "Cultivated "
  },

  LayerDatasetNames : {
    cdl : {
      layerTitle:"Cropland Data Layer (CDL)",
      datasetName:"CDLS_WM"
    },
    soybean : {
      layerTitle:"Soybeans Frequency (2008 - 2024)",
      datasetName:"Soybeans_Frequency"
    },
    wheat : {
      layerTitle:"Wheat Frequency (2008 - 2024)",
      datasetName:"Wheat_Frequency"
    },
    corn: {
      layerTitle:"Corn Frequency (2008 - 2024)",
      datasetName:"Corn_Frequency"
    },
    cultivated: {
      layerTitle: "Cultivated Land (2019-2024)",
      datasetName: "Cultivated"
    },
    cotton: {
      layerTitle: "Cotton Frequency (2008 - 2024)",
      datasetName: "Cotton_Frequency"
    }
  },


  statesSelectionLayerTitle: "States",
  stateNameAttribute: "STATE_NAME",
  stateAbbreviationAttribute: "STATE_ABBR",
  stateGPCacheKeyAttribute: "STATE_FIPS",

  countiesSelectionLayerTitle: "Counties",
  countyNameAttribute: "NAME",
  countyStateNameAttribute: "STATE_NAME",
  countyGPCacheKeyAttribute: "FIPS",

  // https://www.nass.usda.gov/Statistics_by_State/RFO/index.php
  nassRegionsLayerTitle: "NASS Regions",
  nassRegionNameAttribute: "NASS_Region",
  nassRegionGPCacheKeyAttribute: "NASS_Region",

  // define the user-selected, user-drawn, or user-uploaded AOI polygon symbol
  exportSelectionSymbol: {
    type: "simple-fill", // autocasts as new SimpleFillSymbol()
    color: "transparent",
    style: "solid",
    outline: {
      type: "simple-line", // autocasts as new SimpleLineSymbol()
      color: [255, 0, 255],
      style: "solid",
      width: 1.333,
    },
  },

  maskGeometry: {
    rings: [
      [
        [-180, -90],
        [-180, 90],
        [180, 90],
        [180, -90],
        [-180, -90],
      ],
    ],
    spatialReference: { wkid: 4326 },
  },
  maskSymbol: {
    type: "simple-fill",
    color: [0, 0, 0, 0.5],
    style: "solid",
    outline: {
      type: "simple-line", // autocasts as new SimpleLineSymbol()
      color: "transparent",
      style: "solid",
      width: 0,
    },
  },

  // configure optional webmap layer legends within the JSAPI LayerList widget
  layerLegends: [
    {
      title: "Cropland Data Layer (CDL)",
      initiallyOpen: true,
    },
    {
      title: "Cultivated Land (2020-2024)",
      initiallyOpen: false,
    },
    {
      title: "Crop Mask (2020 - 2024)",
      initiallyOpen: false,
    },
    {
      title: "Corn Frequency (2008 - 2024)",
      initiallyOpen: false,
    },
    {
      title: "Soybeans Frequency (2008 - 2024)",
      initiallyOpen: false,
    },
    {
      title: "Cotton Frequency (2008 - 2024)",
      initiallyOpen: false,
    },
    {
      title: "Wheat Frequency (2008 - 2024)",
      initiallyOpen: false,
    },
    {
      title: "Crop_Sequence_Boundary - CSB 2015-2024",
      initiallyOpen: false,
    }
  ],

  // configure the calculate statistics geoprocessor
  calculateStatisticsGeoprocessorUrl:
    "https://pdi.scinet.usda.gov/geoprocessing/rest/services/CalculateStatistics/GPServer/Calculate%20Statistics",

  // configure the export clip+ship+zip geoprocessor
  exportGeoprocessorUrl:
    "https://pdi.scinet.usda.gov/geoprocessing/rest/services/ExportCropImage/GPServer/Clip%20Zip%20Ship",
  exportProjectionOptions: [
    {
      value: "3857",
      label: '<span title="Web Mercator (WGS 84)">Web Mercator (WGS 84)</span>',
    },
    {
      value: "102003",
      label:
        '<span title="USA Contiguous Albers Equal Area Conic (NAD 83)">USA Contiguous Albers Equal Area Conic (NAD 83)</span>',
    },
    {
      value: "32610",
      label: '<span title="UTM Zone 10N (WGS 84)">UTM Zone 10N (WGS 84)</span>',
    },
    {
      value: "32611",
      label: '<span title="UTM Zone 11N (WGS 84)">UTM Zone 11N (WGS 84)</span>',
    },
    {
      value: "32612",
      label: '<span title="UTM Zone 12N (WGS 84)">UTM Zone 12N (WGS 84)</span>',
    },
    {
      value: "32613",
      label: '<span title="UTM Zone 13N (WGS 84)">UTM Zone 13N (WGS 84)</span>',
    },
    {
      value: "32614",
      label: '<span title="UTM Zone 14N (WGS 84)">UTM Zone 14N (WGS 84)</span>',
    },
    {
      value: "32615",
      label: '<span title="UTM Zone 15N (WGS 84)">UTM Zone 15N (WGS 84)</span>',
    },
    {
      value: "32616",
      label: '<span title="UTM Zone 16N (WGS 84)">UTM Zone 16N (WGS 84)</span>',
    },
    {
      value: "32617",
      label: '<span title="UTM Zone 17N (WGS 84)">UTM Zone 17N (WGS 84)</span>',
    },
    {
      value: "32618",
      label: '<span title="UTM Zone 18N (WGS 84)">UTM Zone 18N (WGS 84)</span>',
    },
    {
      value: "32619",
      label: '<span title="UTM Zone 19N (WGS 84)">UTM Zone 19N (WGS 84)</span>',
    },
  ],
  exportFiletypeOptions: [
    {
      value: "TIFF",
      label: '<span title="GeoTIFF">GeoTIFF</span>',
    },
    {
      value: "PNG",
      label: '<span title="PNG">PNG</span>',
    },
    {
      value: "MRF",
      label:
        '<span title="Meta Raster Format (MRF)">Meta Raster Format (MRF)</span>',
    },
    {
      value: "GDB",
      label: '<span title="Geodatabase">Geodatabase</span>',
    },
  ],

  dpiOptions: [
    {
      value: "96",
      label: '<span title="96">96</span>'
    },
    {
      value: "200",
      label: '<span title="200">200</span>'
    },
    {
      value: "300",
      label: '<span title="300">300</span>'
    }
  ],

  // configure the generate features REST URL
  // to process zipped shapefile uploads into temporary graphics
  generateFeaturesUrl:
    "https://pdi.scinet.usda.gov/portal/sharing/rest/content/features/generate",

  // configure the export web map printing service
  exportWebMapTaskUrl:
    "https://pdi.scinet.usda.gov/hosting/rest/services/CroplandCROS_Print_Services/GPServer/Export%20Web%20Map",

  // define the optional cutout masking feature (aka: "cookie cutter graphic") polygon symbol used by the export web map printing service
  cookieCutterSymbol: {
    type: "simple-fill",
    color: "white",
    style: "solid",
    outline: {
      color: "transparent",
      width: 0,
    },
  },

  // non-ag categories
  nonAgCategories: [
    "Forest",
    "Grassland/Pasture",
    "Developed/Med Intensity",
    "Developed/High Intensity",
    "Deciduous Forest",
    "Evergreen Forest",
    "Mixed Forest",
    "Wetlands",
    "Nonag/undefined",
    "Scrub/Shrub Wetlands",
    "Woody Wetlands",
    "Herbaceous Wetlands",
    "Nonag/undefined",
    "Waterways/Ditches",
    "Mixed Water/Clouds",
    "Unconsolidated Shore",
    "Developed",
    "Roads/Railroads",
    "Buildings/Residential",
    "Developed/Open Space",
    "Developed/Low Intensity",
    "Developed/ Medium Intensity",
    "Developed/ High Intensity",
    "Water",
    "Aquatic Beds",
    "Open Water",
    "Shrubland",
    "Barren",
    "Perrennial Ice/Snow",
  ],
};
