<script>
    import {
        layerListStore,
        layerSelectionStore
    } from "../stores";
    import Select from "svelte-select";
  
    export let expanded;
    export let collapseAllCustomDropdowns;
  </script>
  
  <!-- USWDS nav bar child drop down menu -->
  <li class="usa-nav__primary-item collapsible first-menu-item">
    <!--
      To override the auto-closing behavior of the USWDS "dropdown accordion button" below:
      1. Remove the "aria-controls" prop of the <button>
      2. Remove the class "usa-nav__link" from the <button>
      3. Remove the "id" prop of the sibling <ul>
      4. Wire up our own boolean toggling logic for their "aria-expanded" and "hidden" props, respectively.
    -->
    <button
      class="usa-accordion__button usa-current"
      aria-expanded={expanded}
      on:click={() => {
        if (expanded) {
          expanded = false;
        } else {
          collapseAllCustomDropdowns();
          expanded = true;
        }
      }}
    >
      <span>Select Layer</span>
    </button>
    <form
      class="usa-form usa-nav__submenu esri-floating-box-shadow"
      hidden={!expanded}
    >
      <h4 class="submenu-header">Select a layer to view data</h4>
  
      <label class="usa-label label-for-svelte-select" for="layer-combobox">
        Layer
      </label>
      <Select
        inputAttributes={{ name: "layer-combobox", id: "layer-combobox" }}
        placeholder="Select Layer"
        listAutoWidth={false}
        items={$layerListStore.map((layerName) => ({
          value: layerName,
          label: `<span title="${layerName}">${layerName}</span>`,
        }))}
        bind:selectedValue={$layerSelectionStore}
      />
    </form>
  </li>
  
  <style>
    .first-menu-item {
      margin-top: 0px;
      margin-left: 24px;
      --itemHoverBG: #e1eff9;
      --itemIsActiveBG: #005ea2;
      --border: 1px solid #565c65;
      --borderRadius: 0px;
    }

    select {
      width: 300px;
    }
  </style>
  