<script>
  import { onMount } from "svelte";

  // NOTE: importing Plotly was an uphill battle with rollupjs (for now package.json ncp and load via index.html)
  // import Plotly from 'plotly.js-dist';

  export let values = [];
  let chartNode;

  onMount(() => {
    let chartData = [
      {
        type: "bar",
        x: values.map(
          (categoryInfo) => categoryInfo.displayText
        ),
        y: values.map((categoryInfo) => categoryInfo.area),
        marker: {
          color: values.map(
            (categoryInfo) => categoryInfo.colorSwatch
          ),
        },
      },
    ];

    let chartLayout = {
      // title: 'Chart title...',
      xaxis: {
        tickangle: -45,
        fixedrange: true, // disable zoom and pan for this axis
      },
      yaxis: {
        title: "Area (acres)",
        fixedrange: true, // disable zoom and pan for this axis
      },
      font: {
        family:
          "Source Sans Pro Web,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif",
      },
      // the data hover tooltips do not seem to inherit font settings from above, so set it again
      hoverlabel: {
        font: {
          family:
            "Source Sans Pro Web,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif",
        },
      },
      margin: {
        t: 30,
        l: 75,
        b: 125,
        r: 30,
      },
      // height: 400 // default is 450px
    };

    let chartConfig = {
      responsive: true,
      displayModeBar: false,
    };

    Plotly.react(chartNode, chartData, chartLayout, chartConfig);
  });

  function downloadChart() {
    if (chartNode) {
      Plotly.downloadImage(chartNode, {
        format: "png",
        width: 800,
        height: 600,
        filename: "CroplandCROS_AOI_Chart",
      });
    }
  }
</script>

<button class="usa-button" on:click={downloadChart}>Download chart</button>

<div bind:this={chartNode} class="chart-container" />

<style>
  .chart-container {
    /* a rough approximation considering the other elements in the dialog and tab child (see also the table code) */
    height: calc(80vh - 200px);
  }
</style>
