<script>
  export let onYearChange = () => {}
  export let years = []
  export let multiple = false
  export let labelStyle = ""
  export let selectStyle = ""
  export let label = "Select year."
</script>

<label style={labelStyle} class="year-label">
  {label}
  <select
    style={selectStyle}
    class="year-select"
    multiple={multiple}
    on:blur={(obj) => {
      const selectedOptions = Array.from(obj.target.selectedOptions)
      onYearChange(selectedOptions.map(selectedOption => selectedOption.value))
    }}
  >
    {#each years as year}
      <option value={year}>{year}</option>
    {/each}
  </select>
</label>

<style>
  .year-label {
    display: flex;
    margin: 0 !important;
    flex-direction: column !important;
  }

  .year-select {
    margin-top: 8px;
  }
</style>