<script>
  import {
    uploadedFeaturesStore,
    sketchViewModelStore,
    stateSelectionStore,
    nassRegionSelectionStore,
  } from "../stores";
  import { convertShapefileIntoFeatures } from "../utils";

  export let expanded;
  export let collapseAllCustomDropdowns;

  // disable the draw buttons if there is an active sketching/drawing session
  // or if the tools are instantiating and not ready yet
  $: disabledDrawButton =
    !$sketchViewModelStore || $sketchViewModelStore.state === "active";
  // provide a tooltip for user feedback if the tools are disabled
  $: drawTooltip = !$sketchViewModelStore
    ? "Please wait until drawing tools are ready."
    : $sketchViewModelStore.state === "active"
    ? "Please finish your current drawing on the map."
    : "";

  // Boolean to show/hide upload indicator
  let shapefileInProgress = false;
  // wrapper around async generate features util
  async function processShapefile(evt) {
    const uploadFileList = evt.target.files;
    if (!uploadFileList.length) {
      return;
    }

    try {
      // if there is a newly uploaded and processed AOI, attempt to clean up the comboboxes in <SelectArea>
      // in case the user previously had chosen a state/county or nass region
      stateSelectionStore.set(undefined);
      nassRegionSelectionStore.set(undefined);

      shapefileInProgress = true;
      const features = await convertShapefileIntoFeatures(uploadFileList[0]);
      uploadedFeaturesStore.set(features);
      expanded = false;
      shapefileInProgress = false;
      // reset the input element, otherwise the change/input event will not fire when trying to upload the same file as before
      evt.target.value = "";
      // clear the uploadedFeatureStores, whose only purpose was to communicate the newly generated features to <EsriMapView>
      uploadedFeaturesStore.set(false);
    } catch (error) {
      console.error(error);
      // reset the input element, otherwise the change/input event will not fire when trying to upload the same file as before
      evt.target.value = "";
      shapefileInProgress = false;
    }
  }
</script>

<!-- USWDS nav bar child drop down menu -->
<li class="usa-nav__primary-item collapsible desktop-view-title">
  <button
    class="usa-accordion__button usa-current"
    aria-expanded={expanded}
    on:click={() => {
      if (expanded) {
        expanded = false;
      } else {
        collapseAllCustomDropdowns();
        expanded = true;
      }
    }}
  >
    <span>Define Area</span>
  </button>
  <form
    class="usa-form usa-nav__submenu esri-floating-box-shadow action-tool-dropdown"
    hidden={!expanded}
  >
    <h4 class="submenu-header">Define area of interest</h4>

    <div class="usa-form-group" title={drawTooltip}>
      <button
        class="usa-button usa-button--outline width-full"
        disabled={disabledDrawButton}
        on:click|preventDefault={() => {
          // if there is a newly drawn polygon AOI, attempt to clean up the comboboxes in <SelectArea>
          // in case the user previously had chosen a state/county or nass region
          stateSelectionStore.set(undefined);
          nassRegionSelectionStore.set(undefined);

          $sketchViewModelStore.create("rectangle");
          expanded = false;
        }}
      >
        <span
          class="margin-right-1 esri-icon-checkbox-unchecked custom-esri-button-icon"
        />
        Draw rectangle
      </button>

      <button
        class="usa-button usa-button--outline width-full"
        disabled={disabledDrawButton}
        on:click|preventDefault={() => {
          // if there is a newly drawn polygon AOI, attempt to clean up the comboboxes in <SelectArea>
          // in case the user previously had chosen a state/county or nass region
          stateSelectionStore.set(undefined);
          nassRegionSelectionStore.set(undefined);

          $sketchViewModelStore.create("circle");
          expanded = false;
        }}
      >
        <span
          class="margin-right-1 esri-icon-radio-unchecked custom-esri-button-icon"
        />
        Draw circle
      </button>

      <button
        class="usa-button usa-button--outline width-full"
        disabled={disabledDrawButton}
        on:click|preventDefault={() => {
          // if there is a newly drawn polygon AOI, attempt to clean up the comboboxes in <SelectArea>
          // in case the user previously had chosen a state/county or nass region
          stateSelectionStore.set(undefined);
          nassRegionSelectionStore.set(undefined);

          $sketchViewModelStore.create("polygon");
          expanded = false;
        }}
      >
        <span
          class="margin-right-1 esri-icon-polygon custom-esri-button-icon"
        />
        Draw polygon
      </button>
    </div>

    <hr class="submenu-separator" style="margin: 0px" />

    <div class="usa-form-group">
      <label
        class="usa-label file-input-label"
        for="shapefile-input"
        style="cursor: pointer; font-size: 16px !important; font-weight: 400;line-height: 104%"
        title="Select a zipped Shapefile to import"
      >
        <span
          class="margin-right-1 esri-icon-upload custom-upload-icon"
          style="font-size: 16px !important; font-weight: 400 !important;line-height: 1 !important;"
        />
        Import Shapefile
      </label>
      <input
        style="width: 100%"
        id="shapefile-input"
        type="file"
        name="shapefile-input"
        title="Select a zipped Shapefile to import"
        accept=".zip"
        on:change={processShapefile}
        disabled={shapefileInProgress}
      />

      <!-- show a toast/alert when the shapefile is uploading and processing -->
      {#if shapefileInProgress}
        <div class="usa-alert usa-alert--info usa-alert--slim custom-updating">
          <div class="usa-alert__body">
            <p class="usa-alert__text">Uploading Shapefile</p>
          </div>
        </div>
      {/if}
    </div>
  </form>
</li>

<!-- USWDS nav bar child drop down menu -->
<style>
  .action-tool-dropdown h4.submenu-header {
    /* emulate padding used by a nav form that _doesn't_ use the .action-tool-dropdown override class */
    padding: 1rem 1rem 0 1rem;
    /* emulate margin spacing between h4 and first label in the "Select Area" dropdown */
    margin-bottom: 1.5rem;
  }

  /* input file test overrides */
  input[type="file"] {
    display: none;
  }
  .file-input-label {
    padding: 16px 16px !important;
    margin-top: 0px !important;
    line-height: 0.9;
    padding: 1rem;
    font-size: 0.93rem;
    font-weight: 700;
    color: #565c65 !important;
  }

  .file-input-label:hover {
    background-color: #e1eff9 !important;
    color: #005ea2 !important;
  }
  .file-input-label:hover span {
    color: #005ea2 !important;
  }

  .custom-upload-icon {
    position: relative;
    top: 2px;
    color: #565c65;
  }
</style>
