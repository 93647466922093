<script>
  import { DialogOverlay, DialogContent } from "svelte-accessible-dialog";
  import Select from "svelte-select";
  import { handleExportAction } from "../../utils";
  import appConfig from "../../appConfig";
  import {
    cropsSelectionStore,
    exportFeaturesStore,
    exportYearStore,
    exportCropTypesStore,
  } from "../../stores";

  export let isOpen;
  export let close;
  export let exportJobType = null; // null, 'exportAOI', 'print'
  export let exportJobStatus = null; // null, 'processing', 'success', 'failure'
  export let exportJobInfo = null;

  let exportProjectionWKID = appConfig.exportProjectionOptions[0];
  let exportFiletype = appConfig.exportFiletypeOptions[0];
  let exportDpiValue = appConfig.dpiOptions[0];

  // wrapper around GP async export util
  async function beginExport(
    exportFeatures,
    exportYear,
    exportCropTypes,
    exportProjectionWKID,
    exportFiletype,
    exportDpiValue
  ) {
    exportJobType = "exportAOI";
    exportJobStatus = null;
    exportJobInfo = null;

    try {
      exportJobStatus = "processing";

      // typically there will only be 1 feature and 1 cache key, but it is possible to have multiple AOIs at once via the upload shapefile tool
      // here we create a gpCacheKey that is a joined string of the unique individual cache keys found in each feature object
      const gpCacheKey = [
        ...new Set(
          $exportFeaturesStore.map((feature) => feature.attributes._gpCacheKey)
        ),
      ].join("_");

      const jobCompleteInfo = await handleExportAction(
        exportFeatures,
        exportYear,
        exportCropTypes,
        exportProjectionWKID.value,
        exportFiletype.value,
        exportDpiValue.value,
        gpCacheKey,
        function (jobInfo) {
          exportJobStatus = "processing";
          exportJobInfo = jobInfo;
        }
      );

      exportJobStatus = "success";
      exportJobInfo = jobCompleteInfo;
    } catch (error) {
      exportJobStatus = "failure";
      exportJobInfo = error;
    }
  }
</script>

<DialogOverlay {isOpen} onDismiss={close} class="overlay">
  <DialogContent
    aria-label="Export Selected Area"
    class="content dialog-box-content export"
  >
    <div class="dialog-title-wrapper">
      <h2>Export Selected Area</h2>

      <!-- custom close button, wrapped up in a div with the h2 title text -->
      <button
        class="usa-button usa-button--unstyled custom-dialog-button"
        title="Close"
        on:click={close}
      >
        <span class="esri-icon-close" style="vertical-align: top" />
      </button>
    </div>

    <form style="margin: 0 24px 24px 24px;">
      <p style="display: flex">
        <span style="min-width: 128px">Year</span>
        <span style="font-weight: bold">
          {$exportYearStore ? $exportYearStore : "None"}
        </span>
      </p>

      <p style="display: flex">
        <span style="min-width: 128px">Crops selected</span>
        <span style="font-weight: bold">
          {$cropsSelectionStore.length
            ? $cropsSelectionStore.map((c) => c.displayText).join(", ")
            : "All"}
        </span>
      </p>

      <hr class="submenu-separator" />

      <label class="usa-label label-for-svelte-select" for="projection-options">
        Projection
      </label>
      <Select
        inputAttributes={{
          name: "projection-options",
          id: "projection-options",
        }}
        isSearchable={false}
        isClearable={false}
        listAutoWidth={false}
        items={appConfig.exportProjectionOptions}
        bind:selectedValue={exportProjectionWKID}
      />

      <label class="usa-label label-for-svelte-select" for="filetype-options">
        File type
      </label>
      <Select
        inputAttributes={{ name: "filetype-options", id: "filetype-options" }}
        isSearchable={false}
        isClearable={false}
        listAutoWidth={false}
        items={appConfig.exportFiletypeOptions}
        bind:selectedValue={exportFiletype}
      />

      {#if exportFiletype.value=='TIFF' || exportFiletype.value=='PNG'}

      <label class="usa-label label-for-svelte-select" for="dpivalue-options">
        DPI Value
      </label>
      <Select
        inputAttributes={{ name: "dpivalue-options", id: "dpivalue-options" }}
        isSearchable={false}
        isClearable={false}
        listAutoWidth={false}
        items={appConfig.dpiOptions}
        bind:selectedValue={exportDpiValue}
      />
      {/if}

      <button
        class="usa-button width-full"
        style="text-align: center; margin-top: 1.5rem"
        on:click|preventDefault={() => {
          beginExport(
            $exportFeaturesStore,
            $exportYearStore,
            $exportCropTypesStore,
            exportProjectionWKID,
            exportFiletype,
            exportDpiValue
          );

          close();
        }}
      >
        Export
      </button>

      <p class="disclaimer">
        Only the selected area of interest will be included in the exported CDL
        data.
      </p>
    </form>
  </DialogContent>
</DialogOverlay>

<style>
  /* https://github.com/reecelucas/svelte-accessible-dialog#styling */
  :global([data-svelte-dialog-overlay].overlay) {
    /* keep it above the <HeaderToolbar>, otherwise the dialog doesn't block those tools */
    z-index: 1100;
  }

  /* :global([data-svelte-dialog-content].content) {  } */

  /* CSS for custom design */
  :global(.content.dialog-box-content.export) {
    padding: 0rem !important;
    margin: 10vh auto !important;
    width: 480px !important;
    display: flex;
    flex-direction: column;
  }

  /* to aid in aligning the title text and our custom close button */
  .dialog-title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #005ea2;
  }

  .dialog-title-wrapper .custom-dialog-button {
    background-color: #005ea2;
    color: #fff;
    margin-right: 0px !important;
    padding: 18px 20px !important;
    z-index: 1;
  }

  .dialog-title-wrapper .custom-dialog-button:hover {
    background-color: #082e65 !important;
  }

  .dialog-title-wrapper h2 {
    color: #ffffff;
    margin: 16px;
    flex: 1;
    text-align: center;
    position: relative;
    left: 32px;
    font-size: 20px;
    line-height: 100%;
  }

  .disclaimer {
    margin-top: 16px;
    font-style: italic;
    color: #535353;
  }
</style>
