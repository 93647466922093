<script>
  import HeaderToolbar from "./HeaderToolbar.svelte";
  import EsriMapView from "./EsriMapView.svelte";
  import ExportAOIAlerts from "./headerTools/alerts/ExportAOIAlerts.svelte";
  import PrintAlerts from "./headerTools/alerts/PrintAlerts.svelte";

  // these props are bound to the child <HeaderToolbar> which helps "send" them over to the alert components
  // which show different alerts UI depending on the status of the async export GP task
  let exportJobType = null; // null, 'exportAOI', 'print'
  let exportJobStatus = null; // null, 'processing', 'success', 'failure'
  let exportJobInfo = null;
</script>

<main>
  <div class="row banner">
    <div class="banner-main-logo">
      <div class="main-logo-image">
        <img src="images/usda-symbol.png" alt="USDA logo" />
      </div>
      <div class="subidentity-title">
        <div class="desktop-view-title">
          National Agricultural Statistics Service
        </div>
        <div class="agency-title desktop-view-title">
          U.S. DEPARTMENT OF AGRICULTURE
        </div>
      </div>
    </div>
    <div class="app-title">
      <h1 title="Cropland Collaborative Research Outcomes System (CROS)">
        CroplandCROS
      </h1>
    </div>
    <div class="banner-secondary-container">
      <div class="feedback-button-container">
        <a
          href="https://survey123.arcgis.com/share/09ef528fbb58426da5d94cbf20256388?portalUrl=https://USDAARS.maps.arcgis.com"
          class="usa-button usa-button--outline desktop-view-title custom-outline-button"
          target="_blank"
        >
          Feedback
        </a>
      </div>
      <div class="banner-secondary-logo">
        <div class="secondary-logo-image">
          <img src="images/PDI_Logo.png" alt="PDI logo" />
        </div>
        <div class="secondary-logo-title">Powered by PDI</div>
      </div>
    </div>
  </div>

  <HeaderToolbar bind:exportJobType bind:exportJobStatus bind:exportJobInfo />

  <div class="mapview-outer-container">
    <EsriMapView />
  </div>

  <div class="export-alerts-container esri-floating-box-shadow">
    {#if exportJobType === "exportAOI"}
      <ExportAOIAlerts
        bind:exportJobType
        bind:exportJobStatus
        bind:exportJobInfo
      />
    {:else if exportJobType === "print"}
      <PrintAlerts bind:exportJobType bind:exportJobStatus bind:exportJobInfo />
    {/if}
  </div>

  <div class="row footer">
    <ul>
      <li class="desktop-view-title">
        <a
          href="https://www.nass.usda.gov/"
          class="usa-link footer-custom-link"
          target="_blank"
        >
          NASS Home
        </a>
      </li>
      <li class="desktop-view-title">
        <a
          href="https://www.ars.usda.gov/"
          class="usa-link footer-custom-link"
          target="_blank"
        >
          ARS Home
        </a>
      </li>
      <li>
        <a
          href="https://pdi.scinet.usda.gov/portal/apps/sites/#/cropcros/pages/user-guide"
          class="usa-link footer-custom-link"
          target="_blank"
        >
          User Guide
        </a>
      </li>
      <li>
        <a
          href="https://pdi.scinet.usda.gov/portal/apps/sites/#/cropcros"
          class="usa-link footer-custom-link"
          target="_blank"
        >
          Resources
        </a>
      </li>
    </ul>
  </div>
</main>

<style>
  main {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .banner {
    align-items: center;
    padding: 8px 16px;
  }

  .banner-main-logo {
    display: flex;
    flex: 1;
  }

  .main-logo-image {
    width: 57px;
    height: 44px;
    margin-right: 12px;
  }

  .main-logo-image img {
    width: 100%;
    min-width: 57px;
    height: auto;
    position: relative;
    top: 2px;
  }

  .subidentity-title {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #1b1b1b;
  }

  .agency-title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: #1b1b1b;
  }

  .app-title {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
  }

  .app-title h1 {
    margin: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    color: #002d72;
  }

  .beta-tag {
    position: relative;
    top: -4px;
    left: 4px;
    padding: 4px;
    background: #002d72;
    color: #ffffff;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    border-radius: 2px;
  }

  .banner-secondary-container {
    flex: 1;
    display: flex;
    align-self: flex-end;
  }

  .feedback-button-container {
    margin-left: auto;
    margin-right: 24px;
  }

  .custom-outline-button {
    border: 2px solid #005ea2;
    border-radius: 0px;
    box-shadow: none;
  }

  .custom-outline-button:hover {
    background-color: #005ea2;
    color: #ffffff;
    border: 2px solid #005ea2;
    box-shadow: none;
  }

  .banner-secondary-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .secondary-logo-image {
    width: 44px;
    height: 26px;
    margin-bottom: 4px;
  }

  .secondary-logo-image img {
    width: 100%;
    height: auto;
  }

  .secondary-logo-title {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 100%;
    color: #1b1b1b;
  }

  .mapview-outer-container {
    height: 100%;
  }

  .export-alerts-container {
    position: absolute;
    top: 139px;
    right: 60px;
  }

  .footer {
    padding: 16px 20px;
    background-color: #f1f1f1;
  }

  .footer ul {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 0px;
    padding-left: 0px;
    list-style-type: none;
  }

  .usa-link.footer-custom-link {
    color: #212121;
    font-weight: 700;
    text-decoration: none;
  }

  .usa-link.footer-custom-link:hover {
    text-decoration: underline;
  }
</style>
